import { NavLink}from "react-router-dom";
import style from "./SidebarElement.module.scss";
import SVG from 'react-inlinesvg';

const SidebarElement = ({page, onClick = null}) =>{
   
    return(
        <>
        {onClick ?
            <div onClick={onClick} className={style.elementLogout}>
                <SidebarElementContent page={page}  />
            </div>
            :
            <NavLink   className={({isActive}) =>  isActive ?  style.element :  style.elementActive} to={`/admin/${page.toLowerCase()}`}   >
                <SidebarElementContent page={page}/>
            </NavLink>
        }
        </>
        
    )
}

const SidebarElementContent = ({page}) =>{
   
    return(
        <div className={style.element__item}  >
            <SVG src={`/assets/svg/menu-${page.toLowerCase()}.svg`}
            loader={<span className={style.svgLoader}></span>}
            height={24} title={page}/>
            <p>{page}</p>
        </div>
    )
}
export default SidebarElement;