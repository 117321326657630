import style from "./ToiletListElement.module.scss";
import { capitalizeEachWord } from "../../utils/utils";

const ToiletListElement = ({ index, toilet, onClick }) => {

  return (
    <div className={style.toilet} onClick={onClick}>
      <p>{index +1}</p>
      <p>{toilet.name}</p>
      <h3>{toilet.address}</h3>
      <h3>{capitalizeEachWord(toilet.place_type)}</h3>
      <h3>{toilet.overall_rating}</h3>
    </div>
  )
}

export default ToiletListElement;

