import style from "./Carousel.module.scss";
import Slider from "react-slick";
import placeholder from "../../assets/img/no-image.jpg";

const Carousel = ({ images = null }) => {


  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0
  };

  return (
    <div className={style.carousel}>
      {images.length > 1 ?
        <Slider {...settings}>
          {images.map((image, index) => (
            <img className={style.carousel__image} key={index} src={image.photo} alt="toilet" />
          ))}
        </Slider>
        : 
        <img className={style.carousel__img} src={ images[0]?.photo ? images[0].photo : placeholder} alt="toilet"/>
      }
    </div>
  );
}
export default Carousel;