import style from "./Sidebar.module.scss";
import SidebarElement from "../SidebarElement/SidebarElement";
import Modal from "../Modal/Modal";
import { useContext } from "react";
import AuthContext from "../../utils/AuthProvider.jsx";
import SVG from 'react-inlinesvg';
import ButtonFullRounded from "../ButtonFullRounded/ButtonFullRounded";
import { useState } from "react";

const Sidebar = ({ isMenuOpen, toggleMenu }) => {
  const pages = ["Dashboard", "Users", "Toilets"]
  const { logout } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  }

  return (
    <>
      <div className={!isMenuOpen ? `${style.sidebar} ` : `${style.sidebar} ${style.sidebar_active}`}>
        <SVG onClick={toggleMenu} className={style.sidebar__closeBtn} src="/assets/svg/close.svg" height={84} title="Menu" />
        <div className={style.sidebar__list} onClick={toggleMenu}>
          {pages && pages.map((page, index) => (
            <SidebarElement key={index} page={page} />
          ))}
          <SidebarElement page={"Log Out"} onClick={() => setIsModalOpen(true)} />
        </div>

      </div>
      
      <Modal isOpen={isModalOpen}  closeModal={closeModal}>
        <h1>Are you sure?</h1>
        <p>You will be logged out. Do you want to continue?</p>
        <ButtonFullRounded onClick={() => logout()} bgColor={"var(--color-red)"} outlineColor={"var(--color-white)"} text={"Logout"} />
        <ButtonFullRounded onClick={() =>  setIsModalOpen(false)} bgColor={"var(--color-white)"} outlineColor={"var(--color-brown-medium)"} text={"Cancel"} />
      </Modal>
    </>
  )
}
export default Sidebar;