import { useContext, useState, useEffect, useRef } from "react";
import AuthContext from "../../utils/AuthProvider.jsx";
import { useNavigate } from "react-router-dom";
import style from "./Login.module.scss";
import SVG from 'react-inlinesvg';
import ButtonFullRounded from "../../components/ButtonFullRounded/ButtonFullRounded.jsx";


export const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ username: '', password: '' })
  const { login, fetchUser, cookies } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [errorType, setErrorType] = useState(null);

  useEffect(() => {
     const fetchData = async () => {
      try {
        await fetchUser();
        navigate("/admin/dashboard")
      } catch (error) { console.error('', error);}
    }
    
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(formData);
      navigate("/admin/dashboard")
      setErrorType(null)
    } catch (error) {
      if(formData.password == '') setErrorType("password");
      if(formData.username == '') setErrorType("username");
      if (error?.response) setError(' ' + Object.values(error.response?.data)[0]);
      else if (error.message) setError(error.message)
      else setError("An unknown error occurred")
    }
  }


  return (
    <div className={style.login}>
      <form className={style.login__form} onSubmit={onSubmit}>
        <div className={style.login__form__info}>
          <img  className={style.login__form__info__logo} src="/assets/svg/logo.svg" alt="" />
          <h3>Too Poop To Go</h3>
          <h1>Admin Panel</h1>
        </div>
        

        <div className={style.login__form__group}>
          <label htmlFor="username">Username</label>
          <input
           className={errorType == "username" ? style.login__errorInput :""}
            placeholder="Insert Here"
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={(e) =>
              setFormData({ ...formData, username: e.target.value })
            }
            required
          />
        </div>



        <div className={style.login__form__group}>
          
          <label htmlFor="password">Password</label>
          
          <div className={style.login__form__group__bar}>
            <input 
              className={errorType == "password" ? style.login__errorInput : ""}
              placeholder="Insert Here"
              type={
                showPassword ? "text" : "password"
              }
              id="password"
              name="password"
              autoComplete="on" //TODO mettere off
              value={formData.password}
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value })
              }
              required
            />
            
          </div>
          
          <SVG src={showPassword ?`/assets/svg/eye-open.svg`: `/assets/svg/eye-closed.svg`}
              loader={<span style={{ width: "24px" }}></span>}
              height={24} title="React"
              onClick={() => setShowPassword((prev) => !prev)}
            />
          
        </div>

        
        {/* <button type="submit">Submit</button> */}
        <ButtonFullRounded text={"Submit"} onClick={onSubmit}  />
        {error && <p className={style.login__form__error}>{error}</p>}
      </form>

      <p className={style.message}>This login page is exclusively reserved for administrators authorized to access our system's backend. </p>

    </div> 



  );
}

export default Login;