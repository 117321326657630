import style from "./Header.module.scss";
import SVG from 'react-inlinesvg';
import { useState, useContext, useEffect } from "react";
import AuthContext from "../../utils/AuthProvider";
import DropdownMenu from "../DropdownMenu/DropdownMenu";
import { useNavigate } from "react-router-dom";
import { formatDateLong } from "../../utils/utils";
import { useMediaQuery } from '@mui/material';
import Modal from "../Modal/Modal";
import ButtonFullRounded from "../ButtonFullRounded/ButtonFullRounded";

const Header = ({ title, username, children, img, onMenuClick }) => {
  const navigate = useNavigate();
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const { api, cookies, isNetworkError, setIsNetworkError } = useContext(AuthContext);
  const isSmallScreen = useMediaQuery('(max-width:768px)');

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    try {
      var response = await api.get(`/toilet/to-verify-number`, null, cookies.accessToken);
      setNotifications(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  const clickDropdownElement = () => {
    setDropdownVisible(false);
    navigate(
      "/admin/toilets",
      {
        state: {
          searchIndex: "not_verified"
        }
      }
    );
  }

  const handleHoverDropdown = () => {
    setDropdownVisible(false);
  }

  const closeNetworkModal = () => {
    window.location.reload();
  }

  const bellClick = () => {
    setDropdownVisible(!isDropdownVisible)
    fetchNotifications();
  }


  return (
    <>
      <div className={style.header}  >

        <div className={style.header__logo}>
          <SVG onClick={onMenuClick} className={style.header__logo__menu} src="/assets/svg/menu.svg" height={24} title="Menu" />
          <>
            <SVG className={style.header__logo__icon} src="/assets/svg/logo.svg" height={64} title="Logo" />
            <h1>{title}</h1>
          </>
        </div>

        <div className={style.header__user}  >
          {children}
          <p>{formatDateLong(new Date())}</p>
          <div className={style.header__user__notification} onClick={() => bellClick()} >
            <div className={style.header__user__notification__alert} style={!notifications?.toilets_to_verify > 0 ? { backgroundColor: "#00ffff00" } : {}} ></div>
            <SVG src={`/assets/svg/bell.svg`} height={24} title="Notification Bell" />
          </div>
          {/* <p>{username && username}</p> */}
          {/* {!children && <img  src={ img ? img : "/assets/img/profile.jpg"} alt="" />} */}
        </div>
      </div>
      {isDropdownVisible &&
        <DropdownMenu title={"Notification"} top={"68px"} right={"12px"} closeDropdown={handleHoverDropdown} >
          {notifications?.toilets_to_verify > 0 ?
            <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
              <SVG className={style.header__logo__icon} src="/assets/svg/danger.svg" height={50} width={50} title="Danger" />
              <p onClick={() => clickDropdownElement()}>There are {notifications.toilets_to_verify} toilets that need to be verified!</p>
            </div>
            :
            <p>Nothing Here...</p>
          }
        </DropdownMenu>}

      {isNetworkError &&
        <Modal isOpen={isNetworkError} closeModal={closeNetworkModal}>
          <h1 style={{color: "var(--color-red)"}}>Network Error</h1>
          <p> Somthing went wrong! Check your connection. </p>
          <ButtonFullRounded onClick={closeNetworkModal} bgColor={"var(--color-white)"} outlineColor={"var(--color-brown-medium)"} text={"Retry"} />
        </Modal>
      }

    </>
  )
}
export default Header;