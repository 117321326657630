import React, { createContext, useState, useEffect} from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import ApiManager from "./ApiManager";

const AuthContext = createContext();



export const AuthContextProvider= ({children}) => {
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
    const [user, setUser] = useState(null);
    const [isNetworkError, setIsNetworkError] = useState(false);
    const [backToLogin, setBackToLogin] = useState(false);

    const api = new ApiManager(process.env.REACT_APP_API_URL, setIsNetworkError, setBackToLogin);
     
    useEffect(() => {
      fetchUser(cookies.accessToken);
    }, []);

    useEffect(() => {
      if(backToLogin) {
        logout();
      }
    }, [backToLogin]);

    const login = async (credentials) => {
        let expires = new Date();
        expires.setDate(new Date().getDate()+14);
        try {
            const response = await api.post('/user/get-token', credentials);
            const accessToken = response?.data?.token;
            setCookie('accessToken', accessToken, { expires: expires });
            await fetchUser(response?.data?.token);  
        } catch (error) {
            throw error;
        }
    };

    const fetchUser = async (token) => {
      try {
        if (cookies.accessToken || token) {
          if(!token) token = cookies.accessToken;
          const response = await api.get('/user/self-retrieve', null , token);
          const userInfo = response?.data;
          userInfo.accessToken = cookies.accessToken;
          if(userInfo.is_admin == true){
            setUser(userInfo);
          }
          else{
            removeCookie('accessToken');
            navigate('/');
            const error = { "response": {
                "data": { "message": "User is not admin",},
              },
            };
            throw error;
          }
        }
      } catch (error) {
        //throw error;
      }
  };

    const logout = () => {
        removeCookie('accessToken');
        setUser(null);
        navigate('/');
    };

    const value = {
        api,
        user,
        cookies,
        isNetworkError,
        setIsNetworkError,
        login,
        logout,
        fetchUser,
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};
export default AuthContext;



