import style from "./Review.module.scss";
import SVG from 'react-inlinesvg';
import placeholder from "../../assets/img/no-image.jpg"
import { useEffect, useState } from "react";
import { fixStringHTTP, getTimeAgo } from "../../utils/utils";
import { useNavigate } from "react-router-dom";

const Review = ({ review }) => {
  const navigate = useNavigate();
  const [average, setAverage] = useState(0);
  useEffect(() => {
    if (!review) return;
    var sum = parseFloat(review.accessibility_rating)
      + parseFloat(review.cleanliness_rating)
      + parseFloat(review.comfort_rating);
    setAverage((sum / 3).toFixed(1));
  }, []);

  const clickGoToUser = (id) => {
    navigate(`/admin/users/${id}`, { state: id });
  }

  if (review) return (
    <div className={style.review}>
      <div className={style.review__head}>
        <img onClick={() => clickGoToUser(review.user.id)}
          src={review.user?.photo_user ? fixStringHTTP(review.user.photo_user) : placeholder} alt="" />
        <div className={style.review__head__info} >
          <h2 onClick={() => clickGoToUser(review.user.id)} >{review.user?.username}</h2>
          <p> {getTimeAgo(review.created_at)} </p>
        </div>
        <div className={style.review__head__star}>
          <SVG src={`/assets/svg/star.svg`} height={14} title="Star" />
          <p>{average}</p>
        </div>
      </div>

      <p className={style.review__text}>{review?.review} </p>
    </div>
  )
}
export default Review;