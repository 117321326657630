import { useEffect, useState, useContext } from 'react';
import { LineChart, PieChart } from '@mui/x-charts';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import style from './Dashboard.module.scss';
import AuthContext from '../../utils/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { capitalizeFirstLetter } from '../../utils/utils';
import InfoBox from '../../components/InfoBox/InfoBox';
import { useMediaQuery } from '@mui/material';
import { formatDateString } from '../../utils/utils';
import ToiletListElement from '../../components/ToiletListElement/ToiletListElement';

const theme = createTheme({
  typography: {
    fontFamily: 'Manrope, sans-serif',
  },
});


const Dashboard = () => {
  const { api, cookies } = useContext(AuthContext);
  const [infoData, setInfoData] = useState(null);
  const [pieGraphData, setPieGraphData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [toiletTopData, setToiletTopData] = useState(null);
  const isSmallScreen = useMediaQuery('(max-width:768px)');
  const isMediumScreen = useMediaQuery('(max-width:1190px)');

  const navigate = useNavigate();

  useEffect(() => {
    fetchGraph();
    fetchStats();
    fetchTopToilets();
  }, []);

  const fetchGraph = async () => {
    try {
      const response = await api.get('/stats/list', { days: 15 }, cookies.accessToken);
      const response2 = await api.get('/toilet/toilet-distribution', null, cookies.accessToken);
      const transformedData = response2.data.map((item, index) => ({
        id: index,
        value: item.count,
        label: capitalizeFirstLetter(item.place_type),
      }));
      setPieGraphData(transformedData);
      setChartData({
        toilets: response.data.map(item => item["toilet stat"]),
        users: response.data.map(item => item["user stat"]),
        dates: response.data.map(item => formatDateString(item.day).slice(0, 5)),
      });

    } catch (error) {
      console.error(error);
    }
  }

  const fetchStats = async () => {
    try {
      const response = await api.get('/stats/list-all', null, cookies.accessToken);
      setInfoData(response.data);
    } catch (error) { console.error(error); }
  }

  const fetchTopToilets = async () => {
    try {
      const response = await api.get('/toilet/top-rated-toilets', null, cookies.accessToken);
      setToiletTopData(response.data);
    } catch (error) { console.error(error); }
  }

  const toiletGoToPage = (obj) => {
    navigate(`/admin/toilets`, { state: { place: obj } });
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={style.dashboard}>
        <div className={style.dashboard__grid}>
          <span className={style.dashboard__stats}>
            {infoData &&
              <>
                <div>
                  <InfoBox value={infoData?.active_users} text="Active Users" iconName="menu-users" />
                  <InfoBox value={infoData?.completed_badges} text="Badges Assigned" iconName="target" />
                </div>
                <div>
                  <InfoBox value={infoData?.verified_toilets} text="Active Toilets" iconName="toilet" />
                  <InfoBox value={infoData?.ratings_with_review} text="Total Reviews" iconName="bubble" />
                </div>
              </>
            }

          </span>

          <span>
            {pieGraphData &&
              <>
                <h1>Toilets Types</h1>
                <PieChart
                  slotProps={{ legend: { hidden: !isMediumScreen ? false : true } }}
                  colors={['#88CE7D', '#F6C547', '#FF9696', '#7C86E0', '#D771E0', '#5FBD50']}
                  series={[
                    {
                      data: pieGraphData,
                    },
                  ]}
                  width={isSmallScreen ? 420 : 400}
                  height={isSmallScreen ? 220 : 200}
                  sx={{
                    fontFamily: 'Arial, sans-serif',
                    fontSize: '14px',
                    color: '#333',
                  }}
                />
              </>
            }
          </span>

        </div>
        <div className={style.dashboard__grid}>
          <span>
            {chartData && (
              <LineChart
                height={isSmallScreen ? 260 : 390}
                series={[
                  {
                    data: chartData.toilets,
                    label: 'Active Toilets',
                    color: '#88CE7D',
                    lineStyle: { strokeWidth: 2 },
                  },
                  {
                    data: chartData.users,
                    label: 'Active Users',
                    color: '#7C86E0',
                    lineStyle: { strokeWidth: 2 },
                  },
                ]}
                xAxis={[
                  {
                    scaleType: 'point',
                    data: chartData.dates,
                    label: 'Date',
                    labelStyle: { fontSize: '1rem', fontWeight: 'bold', fill: '#5d4037' },
                    tickLabelStyle: { fontSize: '0.8rem', fill: '#5d4037' },
                  },
                ]}
                yAxis={[
                  {
                    label: 'Values',
                    labelStyle: { fontSize: '1rem', fontWeight: 'bold', fill: '#5d4037' },
                    tickLabelStyle: { fontSize: '0.8rem', fill: '#5d4037' },
                  },
                ]}
                sx={{
                  fontFamily: 'Arial, sans-serif',
                  fontSize: '14px',
                  color: '#333',
                }}
              />
            )}
          </span>
        </div>

        <div className={style.dashboard__grid}>
          {toiletTopData &&
            <span className={style.dashboard__topList}>

              <h1 className={style.dashboard__topList__title}>Top Ten Toilets</h1>
              <div className={style.dashboard__topList__header}>
                <p>#</p>
                <p>Name</p>
                <p>Address</p>
                <p>Type</p>
                <p>Stars</p>
              </div>
              {toiletTopData.map((item, i) => {
                return <ToiletListElement key={i} index={i} toilet={item} onClick={() => toiletGoToPage(item)} />
              })}
            </span  >
          }
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Dashboard;
