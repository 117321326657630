import style from "./ButtonCircular.module.scss";
import SVG from 'react-inlinesvg';

const ButtonCircular = ({ className, iconName = "arrow", onClick , bgColor  }) => {
  return (
    <a className={`${style.button} ${className}`} style={{background: "var(--color-white)" }} onClick={() => { onClick() }}>
      <SVG src={`/assets/svg/${iconName}.svg`}
        loader={<span style={{ width: "24px" }}></span>}
        height={24} 
      />
    </a>
  )
}
export default ButtonCircular;