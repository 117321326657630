import React from 'react';
import SVG from 'react-inlinesvg';
import style from './FiveStars.module.scss';

const FiveStars = ({ rating , text }) => {

  const getStarType = (index) => {
    if (rating >= index + 0.7) {
      return '/assets/svg/star.svg'; 
    } else if (rating > index + 0.3  && rating < index + 0.7) {
      return '/assets/svg/star-half.svg'; 
    } else {
      return '/assets/svg/star-empty.svg'; 
    }
  };

  const stars = [0, 1, 2, 3, 4].map((index) => {
    const starType = getStarType(index);
    return (
      <SVG
        key={index}
        src={starType}
        loader={<span style={{ width: "14px" }}></span>}
        height={14}
        width={14}
        title="React"
      />
    );
  });

  return (
    <div className={style.fiveStars}>
      <p>{text}</p>
      <div className={style.fiveStars__stars}>
        {stars}
      </div>
      
    </div>
  );
};

export default FiveStars;
