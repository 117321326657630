import { useContext, useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import MapContext from "../../utils/MapProvider.jsx";
import style from "./Toilets.module.scss";
import Searchbar from "../../components/Searchbar/Searchbar.jsx";
import SearchToiletComponent from "../../components/SearchToiletComponent/SearchToiletComponent.jsx";
import AuthContext from "../../utils/AuthProvider.jsx";
import ToiletDetails from "../../components/ToiletDetails/ToiletDetails.jsx";
import ButtonCircular from "../../components/ButtonCircular/ButtonCircular.jsx";
import Lottie from "lottie-react";
import loading from "../../assets/lottie/DotAnim.json";

const Toilets = () => {
  const { map,  mapContainerRef, addMarker,
    flyTo, placeDetailID, fetchToiletsFromPoint,
    setPlaceDetailID, isLoadingMap } = useContext(MapContext);
  const { api, cookies } = useContext(AuthContext);
  const { state } = useLocation();
  const [searchedPlaces, setSearchedPlaces] = useState([]);
  const [isMobileViewOpen, setIsMobileViewOpen] = useState(false);
  const filterListRef = useRef(null);
  const toiletPageRef = useRef(null);

  useEffect(() => {
    if (map) mapContainerRef.current.style.display = "block";
    mapContainerRef.current.style.display = "block";
    filterListRef.current.style.display = `none`;
  }, []);

  useEffect(() => {
    if (state?.place && state?.place.id !== placeDetailID) {
      setPlaceDetailID(state.place.id);
      
      setTimeout(() => {
        flyTo(state.place.coordinates.coordinates, 18);
        addMarker(state.place);
      }, 600);
    }
    if (state?.searchIndex == "not_verified") {
      state.searchIndex = null
      handleChangeSearch("search", "not_verified")
    }
  }, [state]);

  useEffect(() => {
    if (placeDetailID) setIsMobileViewOpen(true);
  }, [placeDetailID]);

  const handleSearchResultClick = (place) => {
    if (!place) return;
    addMarker(place);
    flyTo(place.coordinates.coordinates, 18);
    setPlaceDetailID(place.id);
  };

  const handleChangeSearch = async (e, searchIndex) => {
    if (placeDetailID) setPlaceDetailID(null);
    if (e.length > 0) {
      if (filterListRef.current) filterListRef.current.style.display = `flex`;
      try {
        let url = "";
        if (searchIndex == "not_verified") url = `/toilet/list-to-verify`;
        else url = `/toilet/list/${e}`;
        const response = await api.get(url, null, cookies.accessToken);
        setSearchedPlaces(response.data.results);
      } catch (error) {
        setSearchedPlaces([]);
        console.log(error);
      }
    }
    else if (filterListRef.current){
      setIsMobileViewOpen(false);
      filterListRef.current.style.display = `none`;
    } 
  };

  const toggleFilterList = () => {
    setIsMobileViewOpen(!isMobileViewOpen);
    if (placeDetailID) {
      filterListRef.current.style.display = `none`;
      setPlaceDetailID(null);
    }
    else if (filterListRef.current.style.display === `none`) {
      filterListRef.current.style.display = `flex`;
    }
    else filterListRef.current.style.display = `none`;
  };

  const resetToiletsMap = () => {
    setSearchedPlaces([]);
    setPlaceDetailID(null);
    fetchToiletsFromPoint();
    setIsMobileViewOpen(false);
  };
  //TODO fai variabile che gestisca chiusura e apertura della pagina search toilet component
  //TODO refactorare quelle volte in cui c'è un componente dentro un div
  return (
    <>
      <div className={style.toilets__overlay}>
        <div className={style.toilets__overlay__searchbar}  style={isMobileViewOpen ?  {display: "flex"} :{}}>
          <Searchbar placeholder={"Search Toilets..."} iconName={"search"} onSubmit={() => null} onChange={handleChangeSearch} />
        </div>

        <ButtonCircular className={style.toilets__overlay__searchBtn} iconName={(isMobileViewOpen) ? "close" : "search"}
          onClick={() => toggleFilterList()} />

        {!placeDetailID ?
          <div className={style.toilets__overlay__filterList} ref={filterListRef}>
            {searchedPlaces?.length > 0 ? searchedPlaces.map((place, index) => (
              <SearchToiletComponent onClick={() => handleSearchResultClick(place)} key={index} toilet={place} />
            )) : <p>No results found...</p>}
          </div>
          : <div ref={filterListRef} ></div>
        }

        {placeDetailID &&
          <div className={style.toilets__overlay__toiletDetail} ref={toiletPageRef}>
            <ToiletDetails toiletID={placeDetailID} onDelete={() => resetToiletsMap()} onAccept={() => fetchToiletsFromPoint()} />
          </div>
        }

        {isLoadingMap &&
          <div className={style.toilets__overlay__loading}>
            <Lottie animationData={loading} loop={true} />
          </div>
        }

      </div>
    </>
  )
}
export default Toilets;