(function() {
    var passiveEvents = [
        "scroll",  "touchstart", "touchmove", "touchenter",
        "touchend", "touchleave", "mouseout", "mouseleave", "mouseup",
       "mouseenter", "mouseover"
    ];


    var supportsPassive = false;
    try {
        var opts = Object.defineProperty({}, 'passive', {
            get: function() {
                supportsPassive = true;
            }
        });
        window.addEventListener("test", null, opts);
        window.removeEventListener("test", null, opts);
    } catch (e) {}

    if (supportsPassive) {
        var originalAddEventListener = EventTarget.prototype.addEventListener;

        EventTarget.prototype.addEventListener = function(type, listener, options) {
            if (typeof options === "object" && options !== null) {
                var useCapture = options.capture !== undefined ? options.capture : false;
                options.passive = passiveEvents.indexOf(type) !== -1 ? true : options.passive;
                options.capture = useCapture;
            }
            else if (typeof options === "boolean") {
                options = {
                    capture: options,
                    passive: passiveEvents.indexOf(type) !== -1
                };
            } else {
                options = {
                    capture: false,
                    passive: passiveEvents.indexOf(type) !== -1
                };
            }

            originalAddEventListener.call(this, type, listener, options);
        };

        EventTarget.prototype.addEventListener._original = originalAddEventListener;
    }
})();
