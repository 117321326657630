import style from "./Modal.module.scss";
import SVG from 'react-inlinesvg';


const Modal = ({ children, width, height, closeModal, isOpen }) => {

  if (isOpen) return (
    <>
      <div className={style.modal}>
        <div className={style.modal__overlay} onClick={() => closeModal()}></div>
        <div className={style.modal__content} style={(width && height) ? { width: `${width}`, height: `${height}` } : { width: `25rem`, height: `15rem` }}>
          <SVG src={`/assets/svg/danger.svg`} loader={<span style={{ width: "24px" }}></span>} height={24} />
          
          {children}
        </div>
      </div>
    </>

  )
}
export default Modal;