import { Outlet } from 'react-router-dom';
import { MapContextProvider } from '../utils/MapProvider';
import AdminLayout from './AdminLayout';
import ProtectedRoutes from './ProtectedRoutes';

const AdminContextLayout = () => {

  return (
        
    <AdminLayout>
      <ProtectedRoutes>
        <MapContextProvider>
          <Outlet />
        </MapContextProvider>
      </ProtectedRoutes>
    </AdminLayout>
       
  );
};

export default AdminContextLayout;