import Searchbar from "../../components/Searchbar/Searchbar";
import React, { useEffect, useState } from 'react';
import style from "./Users.module.scss";
import UserListElement from "../../components/UserListElement/UserListElement";
import { useContext } from "react";
import AuthContext from "../../utils/AuthProvider.jsx";
import SVG from 'react-inlinesvg';

const Users = () => {
  const [users, setUsers] = useState([]);
  const { api, cookies } = useContext(AuthContext);
  const [payloadPage, setPayloadPage] = useState(1);
  const [otherPages, setOtherPages] = useState([]);
  //TODO cancelli i bottoni quando fai la chiamata per nome

  useEffect(() => {
    fetchUsersList();
  }, [payloadPage]);

  //TODO fare funzione divers in base a dove arrivi

  const fetchUsersList = async () => {
    try {
      setUsers([])
      const response = await api.get('/user/list', { page: payloadPage }, cookies.accessToken);
      setOtherPages([response.data.previous, response.data.next]);
      setUsers(response.data.results);
    } catch (error) {
      //setError('Login failed: ' + Object.values(error.response.data)[0]);
    }
  }


  const fetchUsersName = async (e) => {
    if (e.length > 0) {
      setUsers([]);
      try {
        const response = await api.get(`/user/search/${e}`, null, cookies.accessToken);
        setUsers(response.data.results);
        setOtherPages(null);
      } catch (error) {
        //setError('Login failed: ' + Object.values(error.response.data)[0]);
      }
    }
    else {
      fetchUsersList();
    }
  }



  return (
    <div className={style.users}>
      <div className={style.users__searchbar}>
        <Searchbar placeholder={"Search User..."} onChange={(e) => fetchUsersName(e)} iconName={"search"} />
      </div>
      <div className={style.users__tableTop}>
        <p>Username</p>
        <p>Email</p>
        <p>Created</p>
        <p>Status</p>
      </div>
      <div className={style.users__userList}>
        {users?.length > 0 ? users.map((user, index) => (
          <UserListElement key={index} user={user} />
        )) : <p></p>}

        {otherPages &&
          <div className={style.users__userList__buttons}>
             <div className={!otherPages[0] ?  style.users__userList__buttons__disactive : ""}>
              <SVG  onClick={() => setPayloadPage(payloadPage - 1)} src={`/assets/svg/arrow.svg`} height={24} />
            </div>
            <a className={style.users__userList__buttons__value}>{payloadPage}</a>
           
            <div className={!otherPages[1] ?  style.users__userList__buttons__disactive : ""}>
              <SVG  onClick={() => setPayloadPage(payloadPage + 1)} src={`/assets/svg/arrow-right.svg`} height={24} />
            </div>
            
          </div>
        }
      </div>

    </div>
  )
}

export default Users;