import style from "./SearchToiletComponent.module.scss";
import placeholder from "../../assets/img/no-image.jpg";
import InfoTag from "../InfoTag/InfoTag";
import { fixStringHTTP, capitalizeFirstLetter } from "../../utils/utils";

const SearchToiletComponent = ({ toilet, onClick = null }) => {
  const baseUrl = process.env.REACT_APP_API_URL;


  const handleImgLink = (src) => {
    if (src?.startsWith('http')) {
      return fixStringHTTP(src);
    }
    else if (src) {
      return baseUrl + toilet?.photos[0]?.photo
    }
    else return placeholder
  }

  return (
    <div className={style.card} onClick={onClick && onClick}>
      <img className={style.card__img} src={handleImgLink(toilet?.photos[0]?.photo)} alt="" />
      <div className={style.card__info}>
        <div className={style.card__info__top}>
          <h1>{toilet?.name}</h1>
          <h3>{toilet?.address}</h3>
        </div>
        {/* <InfoTag text={toilet.is_free ? "Free" : "Pay"} icon={"wallet"} bgColor={"var(--color-background)"} /> */}
        {!toilet?.is_verified ?
          <InfoTag text={"Not Verified"} bgColor={"var(--color-red)"} icon={"danger"} textColor={"var(--color-white)"} />
          :
          <div className={style.card__info__tags}>
            <InfoTag text={capitalizeFirstLetter(toilet.place_type)} icon={toilet.place_type} bgColor={"var(--color-background)"} />
            {toilet.is_for_disabled &&
              <InfoTag text={"Acc"} icon={"disability"} bgColor={"var(--color-background)"} />
            }
          </div>

        }
      </div>
    </div>
  )
}
export default SearchToiletComponent;