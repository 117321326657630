import { useLocation } from "react-router-dom";
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header/Header";
import style from "../assets/style-module/AdminLayout.module.scss";
import { useContext, useState } from "react";
import AuthContext from "../utils/AuthProvider.jsx";

const AdminLayout = ({ children, username }) => {
  const { user } = useContext(AuthContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  }

  return (
    <>
      <Header title={"Admin"} username={user && user.username} img={user && user.photo_user} onMenuClick={onMenuClick} />
      <Sidebar isMenuOpen={isMenuOpen}  toggleMenu = {onMenuClick}/>
      
      <div className={style.content}>
        {children}
      </div>

    </>
  )
}
export default AdminLayout;