
import { useNavigate } from "react-router-dom";
import ButtonFullRounded from "../../components/ButtonFullRounded/ButtonFullRounded";
import style from "./PageNotFound.module.scss";



const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className={style.page}>
        <h1>404</h1>
        <p>Page Not Found</p>
        <p>
            The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
        </p>
        <ButtonFullRounded text={"Back to Safe Area"} bgColor={"var(--color-brown-dark)"} outlineColor={"var(--color-background)"} onClick={() => navigate(-1)} />
    </div>

  )
}




export default PageNotFound;