
import './App.css';
import './assets/style-module/custom-mapbox.css';
import {Routes, Route } from "react-router-dom";
import Login from './pages/Login/Login.jsx';
import Dashboard from './pages/Dashboard/Dashboard.jsx';
import Users from './pages/Users/Users.jsx';
import Toilets from './pages/Toilets/Toilets.jsx';
import AdminContextLayout from './layouts/AdminContextLayout.jsx';
import AuthContextLayout from './layouts/AuthContextLayout.jsx';
import UserDetail from './pages/UserDetail/UserDetail.jsx';

import PasswordReset from './pages/PasswordReset/PasswordReset.jsx';
import PasswordResetConfirm from './pages/PasswordReset/PasswordResetConfirm.jsx';
import "./set-passive-events.js";
import PageNotFound from './pages/PageNotFound/PageNotFound.jsx';


function App() {
  return (
   
    <>
      <Routes>
        {/* <Route path="/" element={<Home/>} /> */}
        <Route path="*" element={<PageNotFound/>} />

        <Route element={<AuthContextLayout/>}>
          <Route path="/" element={<Login/>}/>
          <Route path="/password-reset" element={<PasswordReset/>}/>  
          <Route path="/password-reset-confirm" element={<PasswordResetConfirm/>}/> 
          
          <Route element={<AdminContextLayout/>}>
            <Route path="/admin/dashboard" element={<Dashboard/>}/>  
            <Route path="/admin/users" element={<Users/>}/>
           
            <Route path="/admin/users/:id" element={<UserDetail/>}/>
            <Route path="/admin/toilets" element={<Toilets/>}/>  
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;

