import style from "./DropdownMenu.module.scss";

const DropdownMenu = ({children, top, right, closeDropdown, title}) => {
  return (
    <div className={style.dropdown} style={{top: top, right: right}} onClick={closeDropdown} onMouseLeave={closeDropdown}  >
      
      <div className={style.dropdown__top}>
        <h1>{title ? title : "Dropdown"}</h1>
      </div>

      <div className={style.dropdown__content}>
        {children}
      </div>

    </div>
  )
}
export default DropdownMenu;