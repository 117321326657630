export const capitalizeFirstLetter = (str) => {
  if (typeof str !== "string" || str.length === 0) {
    return "";
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeEachWord = (str) => {
  if (typeof str !== "string" || str.length === 0) {
    return "";
  }
  return str
    .split(" ")
    .map((word) => capitalizeFirstLetter(word))
    .join(" ");
};

export const camelCaseToKebabCase = (str) => {
  str = str.trim();
  str = str.replace(/\s+/g, "-");
  return str.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
};

export const formatDateString = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const isDateWithinLast48Hours = (apiDate) => {
  const apiDateTime = new Date(apiDate);
  const now = new Date();
  const timeDifference = now - apiDateTime;
  const hoursDifference = timeDifference / (1000 * 60 * 60);
  return hoursDifference < 24;
};

export const prependStringToPhotosData = (data, prependString) => {
  const updatedPhotos = data.photos.map((photo) => ({
    ...photo,
    photo: `${prependString}${photo.photo}`,
  }));

  return {
    ...data,
    photos: updatedPhotos,
  };
};

export const addBrAfterFirstComma = (inputString)  => {
  const index = inputString.indexOf(',');
  if (index !== -1) {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "0rem" }}>
      <p>{inputString.slice(0, index + 1)}</p>
      <p>{inputString.slice(index + 1)}</p>
      </div>
    )
  }
  return inputString;
}

export const fixStringHTTP = (str) => {
  const index = str.indexOf('p');
  if (index === -1) {
    return str;
  }
  return str.slice(0, index + 1) + 's' + str.slice(index + 1);
};

export const formatDateLong = (date) => {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  if (!(date instanceof Date) || isNaN(date)) {
    throw new Error('Invalid date input');
  }
  const day = date.getDate();
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  return `${day}, ${month} ${year}`;
};


export const getTimeAgo = (dateString) => {
  const now = new Date();
  const date = new Date(dateString);
  const seconds = Math.floor((now - date) / 1000);

  const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 },
  ];

  for (let { label, seconds: intervalSeconds } of intervals) {
    const count = Math.floor(seconds / intervalSeconds);
    if (count >= 1) {
      return `${count} ${label}${count > 1 ? 's' : ''} ago`;
    }
  }
  return 'just now';
};

