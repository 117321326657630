import { useContext, useState, useEffect, useRef } from "react";
import AuthContext from "../../utils/AuthProvider.jsx";
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import style from "./PasswordReset.module.scss";
import ButtonFullRounded from "../../components/ButtonFullRounded/ButtonFullRounded.jsx";

  


export const PasswordReset = () => {
  const navigate = useNavigate();
  const { api, cookies  } = useContext(AuthContext);
  const [formData, setFormData] = useState({ email: ''})
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");


  const onSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      email: formData.email
    }
    try {
      const response = await api.post('/user/reset-password', payload );

    } catch (error) {

      if (error?.response) setError(' ' + Object.values(error.response?.data)[0]);
      else if (error.message) setError(error.message)
      else setError("An unknown error occurred")
    }
  }


  return (
    <div className={style.reset}>
      <form className={style.reset__form} onSubmit={onSubmit}>
        <div className={style.reset__form__info}>
          <img className={style.reset__form__info__logo} src="/assets/svg/logo.svg" alt="" />
          <h3>Too Poop To Go</h3>
          <h1>Reset Password</h1>
        </div>


        <div className={style.reset__form__group}>
          <label htmlFor="email">Email</label>
          <input
            placeholder="Insert Here"
            type="email"
            id="email"
            name="email"
            autoComplete="on"
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
            required
            
          />
           

          
        </div>

        <ButtonFullRounded text={"Submit"} onClick={onSubmit} />
        <p className={error ? style.reset__form__error : style.reset__form__errorInactive }> {error ? error : "nulddl"}</p>

        {/* <button type="submit">Submit</button> */}

        
      </form>
    </div>



  );
}

export default PasswordReset;