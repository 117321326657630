import { useContext, useState, useEffect, useRef } from "react";
import { NavLink, Navigate, useLocation } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../utils/AuthProvider.jsx";
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import style from "./PasswordReset.module.scss";
import SVG from 'react-inlinesvg';
import MapContext from "../../utils/MapProvider.jsx";
import Searchbar from "../../components/Searchbar/Searchbar.jsx";
import ButtonFullRounded from "../../components/ButtonFullRounded/ButtonFullRounded.jsx";
import { useParams} from "react-router-dom";





export const PasswordResetConfirm = () => {
  const { api } = useContext(AuthContext);
  const [formData, setFormData] = useState({ password: '', password2: '' })
  const [showPassword, setShowPassword] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const uid = urlParams.get('uid');
  const token = urlParams.get('token');
  const navigate = useNavigate();


  const onSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.password2) {
      setError("Passwords do not match");
      return;
    }
    try {
      await api.post(`/user/reset-password-confirm/${uid}/${token}/`, { new_password: formData.password });
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
  
      if (error?.response) setError(' ' + Object.values(error.response?.data)[0]);
      else if (error.message) setError(error.message);
      else setError("An unknown error occurred");
    }
  }

  useEffect(() => { 
    if (uid === null || token === null) {
      navigate("/password-reset");
    }
  }, []);


  return (
    <div className={style.reset}>
      <form className={style.reset__form} onSubmit={onSubmit}>
        <div className={style.reset__form__info}>
          <img className={style.reset__form__info__logo} src="/assets/svg/logo.svg" alt="" />
          <h3>Too Poop To Go</h3>
          <h1>Reset Password</h1>
        </div>
        {!success ?

          <>
            <div className={style.reset__form__group}>
              <label htmlFor="password1">Password</label>
              <input
                placeholder="Insert Here"
                type={
                  showPassword ? "text" : "password"
                }
                id="password1"
                name="password1"
                autoComplete="off"
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                required
              />
              <SVG src={showPassword ? `/assets/svg/eye-open.svg` : `/assets/svg/eye-closed.svg`}
                loader={<span style={{ width: "24px" }}></span>}
                height={24} title="React"
                onClick={() => setShowPassword((prev) => !prev)}
              />
            </div>
            <div className={style.reset__form__group}>
              <label htmlFor="password2">Repeat Password</label>
              <input
                placeholder="Insert Here"
                type="password"
                id="password2"
                name="password2"
                autoComplete="off"
                value={formData.password2}
                onChange={(e) =>
                  setFormData({ ...formData, password2: e.target.value })
                }
                required
              />
              <ButtonFullRounded text={"Submit"} onClick={onSubmit} />
              <p className={error ? style.reset__form__error : style.reset__form__errorInactive}> {error ? error : "nulddl"}</p>
            </div>
          </>
          :
          <div className={style.reset__success}>
            <h2 >Success!</h2>
            <p>Your password has been reset. <br /> Re-open the app to Login</p>
          </div>
        }
      </form>
    </div>



  );
}

export default PasswordResetConfirm;