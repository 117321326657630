import style from "./Searchbar.module.scss";

const Searchbar = ({ placeholder = null, onSubmit = null, onChange = null, iconName = null }) => {

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && onSubmit) {
      onSubmit();
    }
  };

  return (
    <div className={style.searchbar} >
      <input
        type="text"
        autoComplete="off"
        placeholder={placeholder && placeholder}
        name="search2"
        className={style.toilets__searchbar__input}
        onChange={(e) => { onChange && onChange(e.target.value) }}
        onKeyDown={handleKeyDown}
      />
    </div>
  )
}
export default Searchbar;

