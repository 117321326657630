import { NavLink}from "react-router-dom";
import style from "./UserListElement.module.scss";
import { formatDateString  } from '../../utils/utils.js';

const UserListElement = ({user}) =>{
    return(
        <NavLink to={`${user.id && user.id}`} state={user.id}>
            <div className={style.user__info}>
                <p>{user.username}</p>
                <p>{user.email}</p>
                <p> {formatDateString(user.created_at)}</p>
                <p style={{color: user.is_active? "#7AD56B": "#F6C548"}}>{user.is_active ? "Active" : "Pending" }</p>
            </div>
        </NavLink>       
    )
}
export default UserListElement;

