import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import style from "./UserDetail.module.scss";
import { useContext } from "react";
import AuthContext from "../../utils/AuthProvider.jsx";
import ButtonCircular from "../../components/ButtonCircular/ButtonCircular.jsx";
import placeholder from "../../assets/img/no-image-profile.jpg";
import SVG from 'react-inlinesvg';
import { formatDateLong } from "../../utils/utils.js";
import SearchToiletComponent from "../../components/SearchToiletComponent/SearchToiletComponent.jsx";
import ButtonFullRounded from '../../components/ButtonFullRounded/ButtonFullRounded.jsx';
import Modal from '../../components/Modal/Modal.jsx';


const UserDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { api, cookies } = useContext(AuthContext);
  const [userID, setUserID] = useState(location.state);
  const [user, setUser] = useState(null);
  const [usedToilets, setUsedToilets] = useState(null);
  const [userRatings, setUserRatings] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    if(!userID) {
      const parts = location.pathname.split('/');
      const pageName = parts[parts.length - 1].split('?')[0];
      setUserID(pageName);
      console.log(pageName);
    }
   
    if (!location.pathname) {
      navigate("/admin/users");
      return;
    }

    fetchUser();
    fetchUserExtras();
  }, []);

  const fetchUser = async() => {
    try {
      const response = await api.get(`/user/retrieve/${userID}`, null, cookies.accessToken);
      setUser(response.data);
    } catch (error) { console.error(error); }
  }

  const deleteUser = async() => {
    try {
      await api.delete(`/user/admin-delete-user/${userID}`, cookies.accessToken);
      navigate("/admin/users");
    } catch (error) { console.error(error); }
  }

  const fetchUserExtras = async() => {
    try {
      const response = await api.get(`/toilet/list-user-toilets/${userID}`, null, cookies.accessToken);
      const response2 = await api.get(`/user/list-user-ratings/${userID}`, null, cookies.accessToken);
      setUsedToilets(response.data.results);
      setUserRatings(response2.data.results);
    } catch (error) { console.error(error); }
  }

  const backClick = () => {
    if(location.state) navigate(-1);
    navigate("/admin/users");
  };

  const toiletGoToPage = (obj) => {
    navigate(`/admin/toilets`, { state: { place: obj } });
  };

  const closeAcceptModal = () => {
    setIsDeleteModalOpen(false);
  }

  if (user) return (
    <div className={style.container}>
      <div className={style.userDetail}>
        <ButtonCircular text={"baby"} onClick={backClick} />
        <div className={style.userDetail__container}>

          <div className={style.userDetail__info}>
            <img className={style.userDetail__info__img} src={user.photo_user ? user.photo_user : placeholder} alt="" />
            <div>
              <h1>{user.username}</h1>
              <InfoBar title={"First Name"} value={user.first_name} />
              <InfoBar title={"Last Name"} value={user.last_name} />
            </div>
            <div className={style.userDetail__info__el}>
              <InfoBar title={"Email"} value={user.email} />
              <InfoBar title={"Status"} value={user.is_active ? "Active" : "Pending"} />
              <InfoBar title={"Creation Date"} value={formatDateLong(user.created_at)} />
            </div>
          </div>
          

          <h1 className={style.userDetail__toiletList__title}>{usedToilets?.length > 0 ? "Created Toilets" : ""}</h1>
          <div className={style.userDetail__toiletList}>
            {usedToilets?.length > 0 ? usedToilets.map((toilet, key) => {
              return <SearchToiletComponent key={key} toilet={toilet} onClick={() => toiletGoToPage(toilet)} />
            })
              : <p className={style.userDetail__toiletList__message}>No toilets created yet</p>
            }
            
          </div>


          <ButtonFullRounded  onClick={() => setIsDeleteModalOpen(true)} bgColor={"var(--color-red)"} outlineColor={"var(--color-brown-light)"} text={"Delete User"} />

          {/* {userRatings?.length > 0 && userRatings.map((toilet) => {
            return <Review review={toilet} />
          })} */}
        </div>
      </div>

      <Modal isOpen={isDeleteModalOpen} closeModal={closeAcceptModal}>
        <p>This action is irreversible </p>
        <ButtonFullRounded onClick={() => deleteUser()} bgColor={"var(--color-red)"} outlineColor={"var(--color-white)"} text={"Delete User"} />
        <ButtonFullRounded onClick={() => setIsDeleteModalOpen(false)} bgColor={"var(--color-white)"} outlineColor={"var(--color-brown-medium)"} text={"Cancel"} />
      </Modal>
    </div>
  )
}

const InfoBar = ({ title, value, icon }) => {
  return (
    <div className={style.infoBar}>
      {icon &&
        <SVG src={`/assets/svg/${icon}.svg`} loader={<span style={{ width: "22px" }}></span>} height={22} />
      }
      <p className={style.infoBar__title}> {title}: </p>
      <p className={style.infoBar__value}> {value ? value : " -"}</p>
    </div>
  )
};



export default UserDetail;