import axios from "axios";

class ApiManager {
  axiosClient;
  baseURL;
  errorHandler;
  backToLogin;

  constructor(baseURL, errorHandler, backToLogin) {
    this.axiosClient = axios.create();
    this.baseURL = baseURL;
    this.errorHandler = errorHandler;
    this.backToLogin = backToLogin;
  }

  async initialize() {
    this.axiosClient.defaults.baseURL = this.baseURL;
    this.axiosClient.defaults.headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
  }

  checkError(error) {
    if (error.message === "Network Error") {
      console.log(error.message);
      if (this.errorHandler) {
        this.errorHandler(true);
      }
    } 
    else if (error.response?.data?.detail === "Invalid token.") {
      this.backToLogin(true);
    } 
    else {
      throw error;
    }
  }

  setHeader = (tokenValue) => {
    if (tokenValue) {
      this.axiosClient.defaults.headers[
        "Authorization"
      ] = `Token ${tokenValue}`;
    }
  };

  async get(endpoint, params = null, token = null) {
    await this.initialize();
    try {
      this.setHeader(token);
      const response = await this.axiosClient.get(endpoint, { params });
      return response;
    } catch (error) {
      this.checkError(error);
    }
  }

  async post(endpoint, data, token = null) {
    await this.initialize();
    try {
      this.setHeader(token);
      const response = await this.axiosClient.post(endpoint, data);
      return response;
    } catch (error) {
      this.checkError(error);
    }
  }

  async put(endpoint, data, token = null) {
    await this.initialize();
    try {
      this.setHeader(token);
      const response = await this.axiosClient.put(endpoint, data);
      return response;
    } catch (error) {
      this.checkError(error);
    }
  }

  async patch(endpoint, data, token = null) {
    await this.initialize();
    try {
      this.setHeader(token);
      const response = await this.axiosClient.patch(endpoint, data);
      return response;
    } catch (error) {
      this.checkError(error);
    }
  }

  async delete(endpoint, token = null) {
    await this.initialize();
    try {
      this.setHeader(token);
      const response = await this.axiosClient.delete(endpoint);
      return response;
    } catch (error) {
      this.checkError(error);
    }
  }
}

export default ApiManager;
