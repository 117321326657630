import style from "./InfoTag.module.scss";
import SVG from 'react-inlinesvg';

const InfoTag = ({ icon = null, text = "Tag" , bgColor, textColor= "var(--color-brown-dark)"}) => {

	return (
		<div className={style.tag} style={{background: bgColor}} >
			<SVG src={`/assets/svg/${icon ? icon : "baby"}.svg`}
				loader={<span style={{ width: "18px" }}></span>}
				height={18} 
				preProcessor={code => code.replace(/stroke=".*?"/g, `stroke="${textColor}"`)}
				/>
			<p className={style.tag__text} style={{color: textColor}}>{text}</p>
		</div>
	)
}
export default InfoTag;