
import style from "./ButtonFullRounded.module.scss";

const ButtonFullRounded = ({ text = "Button", onClick = null, bgColor = null , outlineColor }) => {

  return (
    <button className={style.button}
      onClick={onClick && onClick}
      style={{ backgroundColor: bgColor , borderColor: outlineColor, color: outlineColor} } type="">
        <p>{text}</p>
    </button>
  )
}
export default ButtonFullRounded;