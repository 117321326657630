import  { useEffect, useState, useContext, useRef } from 'react';
import style from "./ToiletReviewsPage.module.scss";
import ButtonCircular from "../ButtonCircular/ButtonCircular";
import Review from "../Review/Review";
import AuthContext from "../../utils/AuthProvider";


const ToiletReviewsPage = ({ toiletID, toiletName, onClose }) => {
  const { api , cookies} = useContext(AuthContext);
  const [ratings, setRatings] = useState([]);
  const [nextCount, setNextCount] = useState(0);
  const [page, setPage] = useState(1);
  const reviewElement = useRef(null);

  useEffect(() => {
    if (toiletID) fetchToiletRatings();
  }, []);



  const handleScroll = () => {
    if (reviewElement.current) {
      const { scrollTop } = reviewElement.current;
      var scrollHeight = reviewElement.current.scrollHeight - reviewElement.current.clientHeight;
      var sum = (scrollTop / scrollHeight).toFixed(2);
 
      if (sum >= 1  ) {
        //TODO check reviews if there are more than one page, load when scroll reaches bottom
        //console.log("fetching more");
        //setPage(page + 1);
        //fetchToiletRatings();
      }
    }
  };

  const fetchToiletRatings = async () => {
    if (!toiletID) return;
    const payload = { page: page };
    try {
      const response = await api.get(`/toilet/list-toilet-ratings/${toiletID}`, payload, cookies.accessToken); 
      setNextCount(response.data.next);
      setRatings(rating => [...rating, ...response.data.results]);
    } catch (error) {console.error(error);}
  }

  return (

    <div className={style.reviewPage} ref={reviewElement} onScroll={handleScroll}>
      <div className={style.reviewPage__head}>
        <ButtonCircular iconName={"arrow"} onClick={() => onClose(false)} />
        <p className={style.reviewPage__head__title}> {toiletName}'s <br></br> reviews</p>
      </div>
      
      {ratings?.length > 0  ?  ratings.map((rating, index) => ( 
        <Review key={index} review={rating} />
        )) :
        <p>No reviews yet...</p>
      }
    </div>
  )
}

export default ToiletReviewsPage;