import mapboxgl from 'mapbox-gl';

class ClickableMarker extends mapboxgl.Marker {
    constructor(options = {}, markID = null, coordinates = [12, 12]) {
      super(options); 

      this.markID = markID;
      this.coordinates = coordinates;
    }
    onClick(handleClick) {
      this._handleClick = handleClick;
      return this;
    }
    _onMapClick(e) {
      const targetElement = e.originalEvent.target;
      const element = this._element;

      if (this._handleClick && (targetElement === element || element.contains((targetElement)))) {
        this._handleClick();
      }
    }
};

export default ClickableMarker;
