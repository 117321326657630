import React, { useEffect } from 'react';
import {  Outlet, useNavigate } from 'react-router-dom';
import { useContext } from 'react';

import AuthContext from '../utils/AuthProvider';

const ProtectedRoutes = ({children}) => {
    const navigate = useNavigate();
    const { cookies } = useContext(AuthContext);
    
    useEffect(() => {
        if (!cookies.accessToken) {
            navigate("/");
        }
    }, [cookies]);

    return (
        < >
            {children}
        </>
    );
};

export default ProtectedRoutes;