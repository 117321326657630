import mapboxgl from 'mapbox-gl';
import { createContext, useRef, useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import AuthContext from "../utils/AuthProvider.jsx";
import style from "../pages/Toilets/Toilets.module.scss";
import ClickableMarker from "./ClickableMarkers";
import MapboxMap from "./MapManager.js";
const MapContext = createContext();


mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;

export const MapContextProvider = ({ children }) => {
  const location = useLocation()
  const [map, setMap] = useState(null);
  const mapContainerRef = useRef(null);
  const [center, setCenter] = useState({ lat: 45.4634, lng: 9.1985 });
  const [radius, setRadius] = useState(0);
  const { api, cookies } = useContext(AuthContext);
  const [onMapPlaces, setOnMapPlaces] = useState([]);
  const [placeDetailID, setPlaceDetailID] = useState(null);
  const [reviewsOpen, setReviewsOpen] = useState(false);
  const [isLoadingMap, setIsLoadingMap] = useState(false);
  const geolocateControlRef = useRef(null);
  const [zoom, setZoom] = useState(14);
  let moveTimeout;
  const num = 0;

  useEffect(() => {
    if (!mapContainerRef.current) return;
    if (map) map.resize();
    if (location.pathname === "/admin/toilets") {
      mapContainerRef.current.style.display = "block";
    }
    else {
      mapContainerRef.current.style.display = "none";
    }
  }, [location.pathname]);

  useEffect(() => {
    fetchToiletsFromPoint();
  }, [radius]);

  useEffect(() => {
    const initializeMap = () => {
      const map = new MapboxMap({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/streets-v12",
        center: [center.lng, center.lat],
        zoom: zoom,
        attributionControl: false,
      });
      //map.addControl(new mapboxgl.FullscreenControl())
      map.addControl(new mapboxgl.NavigationControl());
      map.dragRotate.disable();
      map.touchPitch.disable();
      map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: true,
          showUserHeading: true,

        })
      )

      map.on("load", () => {
        setMap(map);
        map.resize();
        updateMapValues(map);
      });

      map.on('move', () => {

      });

      map.on('moveend', () => {
        if (moveTimeout) clearTimeout(moveTimeout);
        moveTimeout = setTimeout(() => {
          //fetchToiletsFromPoint();
          updateMapValues(map);
        }, 1200);
      });
    };

    if (!map) initializeMap();
  }, [map]);



  const updateMapValues = (map) => {
    setZoom(map.getZoom().toFixed(2));
    const center = map.getCenter();
    const bounds = map.getBounds();
    const r = calculateRadius(center, bounds);
    setCenter({
      lat: center.lat, //.toFixed(4)
      lng: center.lng
    });
    setRadius(r);
  }


  const addMarker = (place) => {
    if (!map) return;
    const el = document.createElement('div');
    el.className = style.marker;
    if (place.is_verified === false) el.classList.add(style.marker__danger);

    const pl = new ClickableMarker(el, place.id, place.coordinates.coordinates)
      .setLngLat(place.coordinates.coordinates)
      .onClick(() => {
        flyTo(pl.coordinates, 16);
        setPlaceDetailID(pl.markID);
        el.classList.add(style.marker__selected);
      })
      .addTo(map);

    const exists = onMapPlaces.some(object => object.id === place.id);
    if (!exists) {
      setOnMapPlaces(prev => [...prev, pl])
    }
  }

  const flyTo = (coordinates, zoom) => {
    if (!map) return;

    map.flyTo({
      center: coordinates,
      offset: [171, 0],
      zoom: zoom || 14,
      essential: true
    });
  }

  const removeAllMarkers = () => {
    map.deleteMarkers(onMapPlaces);
  }

  const calculateRadius = (center, bounds) => {
    const corner = bounds.getNorthEast();
    const latDiff = corner.lat - center.lat;
    const lngDiff = corner.lng - center.lng;
    const r = Math.sqrt(latDiff * latDiff + lngDiff * lngDiff);
    return r * (Math.PI / 180) * 6371;
  };

  const fetchToiletsFromPoint = async () => {
    if (!center || radius == 0) return;
    setIsLoadingMap(true);
    try {
      const payload = {
        distance: radius,
        latitude: center.lat,
        longitude: center.lng
      }
      const response = await api.get(`/toilet/list-from-point`, payload, cookies.accessToken);
      removeAllMarkers();
      for (let i = 0; i < response.data.length; i++) {
        addMarker(response.data[i]);
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setTimeout(() => {
        setIsLoadingMap(false);
      }, 1200);
    }
  }

  const value = {
    map,
    center,
    radius,
    mapContainerRef,
    placeDetailID,
    geolocateControlRef,
    isLoadingMap,
    setPlaceDetailID,
    addMarker,
    flyTo,
    removeAllMarkers,
    fetchToiletsFromPoint
  };

  return (
    <>
      <MapContext.Provider value={value}>
        <div className={style.toilets__map} ref={mapContainerRef} id="big_map" ></div>
        {children}
      </MapContext.Provider>
    </>
  )
}

export default MapContext;


