import { useEffect, useRef, lazy, useContext, useState } from "react";
import style from "./ToiletDetails.module.scss";
import ButtonFullRounded from "../ButtonFullRounded/ButtonFullRounded";
import SVG from 'react-inlinesvg';
import AuthContext from "../../utils/AuthProvider";
import InfoTag from "../InfoTag/InfoTag";
import ToiletReviewsPage from "../ToiletReviewsPage/ToiletReviewsPage";
import { capitalizeFirstLetter, isDateWithinLast48Hours, prependStringToPhotosData, addBrAfterFirstComma } from "../../utils/utils";
import Carousel from "../Carousel/Carousel";
import FiveStars from "../FiveStars/FiveStars";
import Modal from "../Modal/Modal";
import { useNavigate } from "react-router-dom";


const ToiletDetails = ({ toiletID = null, onDelete = null, onAccept = null }) => {
  const { api, cookies } = useContext(AuthContext);
  const baseUrl = process.env.REACT_APP_API_URL;
  const [toilet, setToilet] = useState(null);
  const [toiletReviewInfo, setToiletReviewInfo] = useState(null);
  const [reviewsOpen, setReviewsOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (toiletID) fetchToilet();
  }, [toiletID]);

  const fetchToilet = async () => {
    if (!toiletID) return;
    try {
      var response = await api.get(`/toilet/retrieve/${toiletID}`, null, cookies.accessToken);
      try {
        const responseReview = await api.get(`/toilet/retrieve-toilet-rating/${toiletID}`, null, cookies.accessToken);
        setToiletReviewInfo(responseReview.data)
      } catch (error) { console.error(error); }

      setToilet(prependStringToPhotosData(response.data, baseUrl));
      setReviewsOpen(false);
    } catch (error) {
      console.error(error);
    }
  }

  const fetchDelete = async () => {
    try {
      await api.delete(`/toilet/delete/${toiletID}`, cookies.accessToken);
      setToilet(null);
      toiletID = null;
      if (onDelete) onDelete();
    } catch (error) {
      console.error(error);
    }
  }
  const fetchAccept = async () => {
    try {
      await api.patch(`/toilet/verify/${toiletID}`, null, cookies.accessToken);
      setToilet(null);
      toiletID = null;
      if (onDelete) onDelete();
    } catch (error) {
      console.error(error);
    }
  }

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  }

  const closeAcceptModal = () => {
    setIsAcceptModalOpen(false);
  }

  const clickGoToUser = (id) => {
    navigate(`/admin/users/${id}`, { state: id });
  }


  if (toilet && !reviewsOpen) return (
    <div className={style.toilet} >
      {toilet?.photos && <Carousel images={toilet?.photos} />}

      <div className={style.toilet__info}>
      
        <h1>{toilet.name}</h1>
        
      
        <div className={style.toilet__info__address}>
          <SVG src={`/assets/svg/smallPin.svg`} loader={<span style={{ width: "18px" }}></span>} height={18} width={18} title="React" />
          {toilet.address &&
            <p>{toilet.address}</p>
          }
          
        </div>
        

        <div className={style.toilet__info__tagsList}>
          {!toilet.is_verified && <InfoTag text={"Not Verified"} bgColor={"var(--color-white)"} icon={"danger"} textColor={"var(--color-red)"} />}
          {isDateWithinLast48Hours(toilet.created_at) && <InfoTag text={"Newest"} icon={"newest"} />}
          {toilet.is_for_babies && <InfoTag text={"Baby Friendly"} icon={"baby"} />}
          {toilet.is_for_disabled && <InfoTag text={"Accessible"} icon={"disability"} />}
          {toilet.place_type && <InfoTag text={capitalizeFirstLetter(toilet.place_type)} icon={toilet.place_type} />}
          <InfoTag text={toilet.is_free ? "Free" : "Pay"} icon={"wallet"} />
        </div>

        {toilet.created_by_name &&
            <p className={style.toilet__info__user} onClick={() => clickGoToUser(toilet.created_by)}>created by <strong>{toilet.created_by_name}</strong> </p>
        }
  
        {toiletReviewInfo &&
          <>
            <div className={style.toilet__info__reviews}>
              <h2>Reviews({toiletReviewInfo.review_count}):</h2>
              <a className={style.toilet__info__btn} onClick={() => setReviewsOpen(true)}>See All</a>
              <SVG src={`/assets/svg/star.svg`} height={14} />
              <p> {toiletReviewInfo.overall_rating}</p>
            </div>

            <div className={style.toilet__info__stars}>
              <FiveStars rating={toiletReviewInfo.accessibility_rating} text={"Accessible"} />
              <FiveStars rating={toiletReviewInfo.cleanliness_rating} text={"Clean"} />
              <FiveStars rating={toiletReviewInfo.comfort_rating} text={"Comfort"} />
            </div>
          </>
        }


        <div className={style.toilet__info__buttons}>
          <h2>Actions:</h2>
          {!toilet.is_verified &&
            <ButtonFullRounded onClick={() => setIsAcceptModalOpen(true)} bgColor={"var(--color-green)"} outlineColor={"var(--color-brown-light)"} text={"Approve"} />
          }
          <ButtonFullRounded onClick={() => setIsDeleteModalOpen(true)} bgColor={"var(--color-red)"} outlineColor={"var(--color-brown-light)"} text={"Delete"} />
        </div>
      </div>


      <Modal isOpen={isDeleteModalOpen} closeModal={closeDeleteModal}>
        <h1>Are you sure?</h1>
        <p>This action will permanently delete the item. </p>
        <ButtonFullRounded onClick={() => fetchDelete()} bgColor={"var(--color-red)"} outlineColor={"var(--color-white)"} text={"Delete"} />
        <ButtonFullRounded onClick={() => setIsDeleteModalOpen(false)} bgColor={"var(--color-white)"} outlineColor={"var(--color-brown-medium)"} text={"Cancel"} />
      </Modal>


      <Modal isOpen={isAcceptModalOpen} closeModal={closeAcceptModal}>
        <h1>Are you sure?</h1>
        <p>This toilet will be made accessible to the public.  </p>
        <ButtonFullRounded onClick={() => fetchAccept()} bgColor={"var(--color-green)"} outlineColor={"var(--color-white)"} text={"Approve"} />
        <ButtonFullRounded onClick={() => setIsAcceptModalOpen(false)} bgColor={"var(--color-white)"} outlineColor={"var(--color-brown-medium)"} text={"Cancel"} />
      </Modal>

    </div>


  )
  else if (toilet && reviewsOpen) return (
    <ToiletReviewsPage toiletID={toiletID} toiletName={toilet.name} onClose={setReviewsOpen} />
  );
}







export default ToiletDetails;