import style from "./InfoBox.module.scss";
import SVG from 'react-inlinesvg';

const InfoBox = ({ value = 0, text = "Tag", iconName  }) => {

  return (
    <div className={style.info}>
      <SVG src={`/assets/svg/${iconName}.svg`}  loader={<span style={{ width: "30px" }}></span>} height={30} width={30} />
      <div>
        <h2>{value}</h2>
        <p>{text}</p>
      </div>
    </div>
  )
}
export default InfoBox;