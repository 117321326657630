import mapboxgl from "mapbox-gl";

class MapboxMap  extends mapboxgl.Map  {
  constructor(options = {}) {
    super(options); 
  }

  deleteMarkers(markerArray) {
    for (let i = 0; i < markerArray.length; i++) {
      markerArray[i].remove();
    }
  }

}

export default MapboxMap;
