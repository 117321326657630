import { Outlet } from 'react-router-dom';
import { AuthContextProvider } from '../utils/AuthProvider';

const AuthContextLayout = () => {

  return (
      <AuthContextProvider>
        <Outlet/>
      </AuthContextProvider>  
  );
};

export default AuthContextLayout;